import React from 'react';
import CTABar2 from 'components/cta_bar_2';
import TestimonialFeatureAndFAQ from 'components/testimonial_feature_and_faq';
import TeamShowcaseSlider from 'components/team_showcase_slider';
import ShortTestimonialSlider from 'components/short_testimonial_slider';
import AssetFinancingProcessSteps from 'components/asset_financing_process_steps';
import Contact1 from "components/contact_1";
import BrandLogosSlider from 'components/brand_logos_slider';
import LenderLogosSlider from 'components/lender_logos_slider';

const ContactPage = () => {
    return (
        <>
            <div id="header-area-space"></div>
            
            {/* Lead Capture */}
            <Contact1 />

            {/* Lender Logo Slider */}
            <LenderLogosSlider />

            {/* CALL-US CTA Bar */}
            <CTABar2 />
            
            {/* ASSET FINANCE PROCESS  */}
            <AssetFinancingProcessSteps />

            {/* Short Tertimonial Slider */}
            <ShortTestimonialSlider />
            

        </>
    )
}

export default ContactPage