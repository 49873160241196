import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';

import LargeHeroBanner from '../../components/large_hero_banner';

const Contact1 = ({
  bgOverride
}) => {
  
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errored, setErrored] = useState(false);
  
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [value, setValue] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [partner, setPartner] = useState();
    // const [loanType, setLoanType] = useState('business loan');
    const [title, setTitle] = useState()
  
    const submit = (e) => {
        e && e.preventDefault();
    
        setSubmitting(true);
        setTimeout(() => {
          setSubmitted(true);
          setSubmitting(false);
          window.Intercom('shutdown');
          window.Intercom('boot', {  
            app_id: 'p1qcm32n',  
            email: emailAddress,
            name: `${firstName} ${lastName}`,
            contact_number: contactNumber
          });
          window.Intercom('show');
        }, 500)
      }

    return (
      <>

            {/* Header Space */}
            <div id="header-area-space" className="slider-area bg-light-primary"/>

            <section className="bg-common-dark hero-banner" data-bg-image={bgOverride || `/img/slider/slider1-1.jpg`} style={{minHeight: "75vh", overflowX: "hidden"}}>
            <div className="container pb-5">
            <div className="row">
              <div className="col-lg-12 col-12 pt-4" style={{marginTop: "10vh"}}>
                <div className="calculation-content margin-b-30r md--space-b-70r mt-4" style={{color: "white"}}>

          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-lg-6 col-12 pt-4">
                <div className="calculation-content margin-b-30r md--space-b-70r mt-4" style={{color: "white"}}>
                  
                  <h2 className="title title-light" style={{fontWeight: "400", marginBottom: "10px"}}>Contact Us</h2>
                  <h2 className='color-primary' style={{fontWeight: "400"}}>Your Finance Specialists</h2>
                  
                  <ScrollAnimation animateIn="fadeInUp">
                  <p>Our expert team of financial specialists are only a moment away. We're here to help! Submit your details for a callback or start a chat with the chat service at the bottom right of this screen.</p>
                  </ScrollAnimation>
                  <ul>
                    <ScrollAnimation animateIn="fadeInRight">
                    <li>One dedicated specialist from start to finish</li>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInRight" delay={150}>
                    <li>A comprehensive range of finance products</li>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInRight" delay={300}>
                    <li>Tailored finance solutions</li>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInRight" delay={450}>
                    <li>Ongoing finance support</li>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInRight" delay={700}>
                    <li>Market leading rates</li>
                    </ScrollAnimation>
                  </ul>

                  {/* Call us button */}
                  <br/>
                  <a href="tel:+611300380041" className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2">Call Us: 1300 380 041 </a>

                </div>
              </div>
              <div className="col-lg-6 col-12">
                <ScrollAnimation animateIn="fadeInRight" >
                <div className="calculation-loan margin-b-30r mt-5" style={{boxShadow: "2px 2px 0px 0px #424242", border: "1px solid #e2e2e2", maxWidth: "550px", margin: "auto auto", backgroundColor: "rgba(255,255,255,0.9)", borderRadius: "15px"}}>
                  <form id="loan-calculate-form">

                  
                    <div>
                        <div className='w-100 text-center rounded'>
                            <ScrollAnimation animateIn="fadeIn" >
                            {errored ? <h3>Uh oh! There was an error.</h3> : submitted ? <><h3>We're ready to chat! <i className='fa fa-chevron-right'/></h3> </> : submitting ? <h3>Connecting you now...</h3> : <p className='alert alert-secondary rounded text-center' style={{fontSize: "0.9em"}}>Please enter your details below to start a conversation.</p>}
                            </ScrollAnimation>
                        </div>
    
                        {
                        submitting ?  <div className='text-center w-100'>Just a moment...</div> :
                        errored ? <div className='text-center' style={{color: 'black'}}>
                            <i className='fa fa-times-circle' style={{color:"black", fontSize: "25px", marginBottom: "15px"}}></i>
                            <p className='lead' style={{color: "white"}}>Something went wrong when submitting your referral, please refresh the page and try again.</p></div> :
                        submitted ? <div className='text-center'>
                            
                        </div> :
                        <form onSubmit={submit} className='w-100'>
                            <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <input type="text" className="form-control" style={{padding: "7px 10px", height: "50px",  border: "1px solid #dedede", fontSize: "16px"}} placeholder="First Name" required value={firstName} onChange={e => setFirstName(e.target.value)} disabled={submitting}/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                <input type="text" className="form-control" style={{padding: "7px 10px", height: "50px", border: "1px solid #dedede", fontSize: "16px"}} placeholder="Last Name" required value={lastName} onChange={e => setLastName(e.target.value)} disabled={submitting}/>
                                </div>
                            </div>
                            </div>
    
                            
                            <div className="form-group">
                            <input type="text" className="form-control" style={{padding: "7px 10px", height: "50px", border: "1px solid #dedede", fontSize: "16px"}} placeholder="Mobile Phone" required value={contactNumber} onChange={e => setContactNumber(e.target.value)} disabled={submitting}/>
                            </div>
                            <div className="form-group">
                            <input type="email" className="form-control" style={{padding: "7px 10px", height: "50px", border: "1px solid #dedede", fontSize: "16px"}} placeholder="Email Address" required value={emailAddress} onChange={e => setEmailAddress(e.target.value)} disabled={submitting}/>
                            </div>
                            
                            
                            
                            <button className="btn-ghost-hybrid color-dark w-100 mt-1 mb-5" style={{textAlign: "center"}} disabled={submitting} onClick={submit}>Start Conversation</button>
                            
                        </form>
                        
                        }
                        <div className="text-center tc-text w-100 text-center" style={{fontSize: "0.7em"}}>
                          <p className='text-small text-center' style={{color: "black"}}>By submitting you agree to the <a href="/privacy">Finstead Privacy Policy</a> and <a href="/terms">Terms of Service</a>.</p>
                        </div>
                    </div>
                    
                  </form>
                </div>
                </ScrollAnimation>
              </div>
              
            </div>
          </div>
          </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
}

export default Contact1;